const questions = [
  {
    question: 'What was your first car (make and model)?',
    questionId: 0,
  },
  {
    question: 'What is your favorite pet\'s name?',
    questionId: 1,
  }, {
    question: 'What is the last name of your favorite teacher?',
    questionId: 2,
  },
  {
    question: 'What is your best friend\'s name?',
    questionId: 3,
  },
  {
    question: 'What is your favorite sports team?',
    questionId: 4,
  }, {
    question: 'Where did you go on your honeymoon?',
    questionId: 5,
  },
  {
    question: 'What is the first name of your oldest niece/nephew?',
    questionId: 6,
  }];

export default questions;
